@import url(https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&family=Arimo:wght@700&family=Heebo&family=Rubik&family=Secular+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Amatic+SC&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Almoni';
  src: url(/static/media/Almoni-Tzar-DL-4.0-AAA-Bold.f67f0189.eot);
  src: url(/static/media/Almoni-Tzar-DL-4.0-AAA-Bold.f67f0189.eot?#iefix) format('embedded-opentype'),
		url(/static/media/Almoni-Tzar-DL-4.0-AAA-Bold.5a72daa0.woff2) format('woff2'),
		url(/static/media/Almoni-Tzar-DL-4.0-AAA-Bold.ddb6e626.woff) format('woff'),
		url(/static/media/Almoni-Tzar-DL-4.0-AAA-Bold.11e560ec.ttf) format('truetype'),
		url(/static/media/Almoni-Tzar-DL-4.0-AAA-Bold.58c9de6c.svg) format('svg');
}

*, html, body {
  /* font-family: 'Rubik', sans-serif !important; */
  font-family: 'Almoni' !important;
  white-space: nowrap;
  overflow-x: hidden;
}

.Arimo {
  font-family: 'Arimo', sans-serif !important;
}

.AmaticSc {
  font-family: 'Amatic SC', cursive !important;
}

.Heebo {
  font-family: 'Heebo', sans-serif !important;
}

.Rubik {
  font-family: 'Rubik', sans-serif !important;
}

.Secular {
  font-family: 'Secular One', sans-serif !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.slider-picker {
  max-width: 100% !important;
}

.appbar, .MuiAppBar-positionStatic {
  background-image: linear-gradient(120deg, #00e4d0, #5983e8) !important;
}

.addButton {
  background-image: linear-gradient(120deg, #00e4d0, #5983e8) !important;
  color: white;
  font-size: 1.2em !important;
}

.headerColor {
  background: -webkit-linear-gradient(#00e4d0, #5983e8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.chrome-picker  {
  font-size: 2em !important;
}

